@CHARSET 'UTF-8' {}

@import 'variables';
@import 'mixins';


[class^="fi-"]::before,
[class*=" fi-"]::before {
    margin-right: 0;
    margin-left: 0; }


@import 'master';

body {
	font-family: 'Roboto Slab', serif;
	-webkit-font-smoothing: antialiased; }
header {
	min-height: 80px;
	.navbar-default {
		.navbar-toggle {
			border: none;
			position: static;
			margin: 0;
			background-color: #00c3ff;
			border-radius: 0;
			height: 100%;
			width: 75px;
			padding: 27px;
			.icon-bar {
				background-color: white; }
			&:hover, &:focus {
				background-color: #00c3ff; } } }
	#navbar {
		min-height: 80px;
		margin-bottom: 0;
		background-image: none;
		background-color: transparent;
		border-bottom: none;
		position: fixed;
		z-index: 999;
		background-color: white;
		width: 100%;
		#logo {
			// background-image: url(../images/logo_seven.svg)
			background-repeat: no-repeat;
			display: block;
			height: 51px;
			width: 130px;
			padding: 0;
			margin-top: 14px;
			margin-left: 37px;
			z-index: 99;
			@media (max-width: 991px) {
				margin-left: 12px; }
			&:hover {
				transform: scale(1.01);
				transition: 0.1s; } }
		.sub-arrow {
			display: none; }
		.navbar-nav {
			> li {
				> a:hover, &:focus {
					color: $secondaryColor;
					border-bottom: 2px solid;
					-webkit-transition: 0.1s;
					transition: 0.1s; } } }
		@media (min-width: 768px) {
			.navbar-nav {
				min-height: 77px;
				position: relative;
				margin-left: -20px;
				display: table;
				@media (min-width: 992px) {
					float: right; }
				@media (max-width: 1250px) {
					display: table;
					width: 100%;
					box-sizing: border-box;
					-moz-box-sizing: border-box;
					-webkit-box-sizing: border-box;
					>li {
						float: none; } }
				> li {
					height: 80px;
					display: table-cell;
					float: none;
					> a {
						color: $primaryColor;
						padding-top: 30px;
						padding-bottom: 28px;
						white-space: nowrap; }
					ul.menu_level_1 {
						display: none;
						position: absolute;
						background-color: $primaryColor;
						text-align: left;
						z-index: 999;
						padding-left: 0;
						@media screen and (min-width: 768px) {
							width: 255px !important; }
						> li {
							list-style: none;
							position: relative;
							border-bottom: 1px dotted #0182a8;
							> a {
								display: block;
								padding: 12px;
								color: white;
								&:hover, &:focus {
									background-color: #1b75bb;
									text-decoration: none; } }
							ul.menu_level_2 {
								padding-left: 0;
								display: none !important;
								position: absolute;
								left: 100%;
								background-color: #00a4d4;
								width: 100%;
								top: 0px;
								li {
									list-style: none;
									border-bottom: 1px dotted #0182a8;
									position: relative;
									a {
										padding: 12px;
										padding-left: 20px;
										display: block;
										color: white;
										&:hover, &:focus {
											background-color: #1b75bb;
											text-decoration: none; } }
									ul.menu_level_3 {
										display: none;
										padding-left: 0;
										background-color: $primaryColor;
										position: absolute;
										top: 0;
										left: 100%;
										width: 100px;
										>li {
											list-style: none;
											border-bottom: 1px dotted #0182a8;
											a {
												padding: 12px;
												padding-left: 20px;
												display: block;
												color: white; } } } } } }
						.active {
							> a {
								background-color: #1b75bb;
								text-decoration: none;
								border-bottom: none; } }
						ul.menu_level_1 {
							display: block; } }
					> .has-submenu {
						position: relative;
						background-image: url(../images/icons/arrow_down_menu.svg);
						background-position: center 60px;
						background-size: 10px;
						background-repeat: no-repeat; } }
				>.active {
					> a {
						background: transparent;
						box-shadow: none;
						border-bottom: 2px solid; } } } }




		@media screen and (min-width: 1700px) {
			.menu-right {
				margin-left: 110px; } }
		.menu-right, .tablet-show {

			ul {
				padding-left: 0;
				margin-top: 17px;
				text-align: right;
				li {
					list-style-type: none;
					a {
						color: $secondaryColor;
						&:hover, &:focus {
							color: #9eda47;
							text-decoration: none;
							-webkit-transition: 1s;
							transition: 1s; } } } }
			.order-btn {
				margin: 16px auto 0;
				border-color: rgb(239, 239, 239);
				@media (max-width: 991px) {
					margin: 15px 0 0 9px; } } }
		@media (max-width: 767px) {
			.navbar-header {
				height: 75px; }
			.navbar-nav {
				li {
					a {
						text-align: left;
						border-bottom: 1px dotted #0182a8;
						display: block;
						position: relative;
						color: $primaryColor;
						padding: 12px;
						.sub-arrow {
							display: block;
							position: absolute;
							right: 0;
							top: 0;
							height: 40px;
							width: 40px;
							color: transparent;
							background-repeat: no-repeat;
							background-size: 17px;
							background-position: center; }
						&:hover, &:focus {
							color: white;
							text-decoration: none; }
						&:focus {
							color: $primaryColor; } } }
				> li {
					> a {
						position: relative;
						&:hover, &:focus {
							color: $primaryColor; }
						.sub-arrow {
							background-image: url(../images/icons/arrow_down_blue.svg);
							color: transparent; } }
					> .highlighted {
						.sub-arrow {
							background-image: url(../images/icons/arrow_up_blue.svg); } }
					ul.menu_level_1 {
						display: none;
						padding-left: 0;
						background-color: $primaryColor;
						>li {
							list-style-type: none;
							> a {
								padding: 12px;
								color: white;
								.sub-arrow {
									background-image: url(../images/icons/arrow_down.svg);
									color: transparent; } }
							>.highlighted {
								.sub-arrow {
									background-image: url(../images/icons/arrow_up.svg); } }
							ul.menu_level_2 {
								display: none;
								background-color: #00a4d4;
								padding-left: 0;
								>li {
									list-style: none;
									a {
										padding: 12px;
										color: white; }
									ul.menu_level_3 {
										display: none;
										padding-left: 0;
										background-color: $primaryColor;
										>li {
											list-style: none;
											a {
												padding: 12px;
												color: white; } } } } } } } } } } } }
.modal-dialog {
	button.button {
		position: absolute;
		bottom: 15px;
		right: 48%;
		background-color: $secondaryColor;
		padding: 10px;
		color: white;
		font-weight: bolder;
		&:hover {
			background-color: $primaryColor; } }
	.col-sm-10 {
		margin-bottom: 50pxg; } }
button.close {
	background-color: $secondaryColor;
	opacity: 1;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	color: white;
	margin: 13px 12px 0 0;
	&:hover {
		background-color: $primaryColor;
		color: white; } }
.modal-body {
	padding-bottom: 70px;
	input {
		width: 100%;
		margin-bottom: 22px;
		padding: 10px; } }

.lSAction {
	> .lSPrev {
		background-image: url(../images/icons/arrow_left_blue.svg); }
	> .lSNext {
		background-image: url(../images/icons/arrow_right_blue.svg); }
	> .lSNext, .lSPrev {
		background-position: center;
		height: 40px;
		width: 40px;
		background-repeat: no-repeat;
		top: 40%; } }
@media screen and (max-width: 1200px) {
	.container {
		max-width: 1200px;
		width: 100%; } }
@media screen and (max-width: 1170px) {
	.container {
		max-width: 1170px;
		width: 100%; } }
@media screen and (max-width: 1250px) and (min-width: 768px) {
	header {
		min-height: 150px;
		.header-menu {
			width: 100%; } } }
@media screen and (max-width: 991px) {
	.container {
		max-width: 991px;
		width: 100%; } }
@media screen and (max-width: 767px) {
	.container {
		max-width: 767px;
		width: 100%; }
	header {
		#navbar {
			.container-fluid {
				padding-left: 0;
				padding-right: 0; }
			#logo {
				position: absolute; } } }
	.navbar-nav {
		margin-top: 0; } }
.order-btn {
	background-color: $secondaryColor;
	display: block;
	text-align: center;
	height: 45px;
	width: 140px;
	white-space: nowrap;
	margin-top: 16px;
	border-radius: 100px;
	color: white;
	font-weight: bolder;
	padding: 8px;
	border: 3px solid #00a7d8;
	&:hover, &:focus {
		background-color: #9eda47;
		color: white;
		text-decoration: none;
		-webkit-transition: 0.2s;
		transition: 0.2s; } }
main {
	position: relative;
	min-height: 600px;
	.blue-back {
		min-height: 500px; } }
.content-web {
	// padding-left: 15px
	// padding-right: 15px
	padding-top: 30px;
	font-size: 16px;
	margin-bottom: 35px;
	font-weight: 100;
	img {
		max-width: 100%;
		display: inline-block;
		position: relative;
		top: -3px; }
	h2 {
		text-align: left;
		font-weight: 400;
		color: #1269ad;
		font-size: 28px;
		text-transform: initial;
		margin-top: 0; } }

.hidden-content {
	display: none; }
.sm-simple {
	border: 0 none;
	background: none;
	@include box-shadow('none'); }

@media (min-width: 768px) {
	#main-menu > ul > li {
		float: none;
		display: table-cell;
		width: 1%;
		text-align: center; } }
.reveal-overlay {
	display: none;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1005;
	background-color: rgba(10,10,10,.45);
	overflow-y: scroll; }
#messageModal {
	text-align: center;
	background-color: #fff;
	background-size: 15%; }

.reveal {
	width: 600px;
	max-width: 75rem;
	display: none;
	z-index: 1006;
	padding: 1rem;
	border: 1px solid #cacaca;
	border-radius: 5px;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	overflow-y: auto; }

.close-button {
	background: #e98b23;
	color: #fff;
	-webkit-border-radius: 999px;
	-moz-border-radius: 999px;
	-ms-border-radius: 999px;
	border-radius: 999px;
	width: 30px;
	height: 30px;
	-webkit-transition: all .5s;
	transition: all .5s;
	-moz-transition: all .5s;
	position: absolute;
	right: 1rem;
	top: .5rem;
	font-size: 2em;
	cursor: pointer; }


.main-nav {
	position: relative;
	top: 0;
	left: 0;
	z-index: 1000;
	padding-top: 15px;
	padding-bottom: 15px;
	width: 100%;
	background-color: $tertiaryColor;
	&:after {
		clear: both;
		content: "\00a0";
		display: block;
		height: 0;
		font: 0px/0 serif;
		overflow: hidden; } }

.nav-brand {
	float: left;
	margin: 0;
	a {
		display: block;
		padding: 11px 11px 11px 20px;
		color: #555;
		font-size: 20px;
		font-weight: normal;
		line-height: 17px;
		text-decoration: none;
		height: 60px;
		img {
			height: 100%;
			width: auto; } } }
@media (min-width: 768px) {
	.nav-brand {
		a {
			height: 100px; } } }


#main-menu > ul {
	clear: both;
	border: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	display: none;
	li {
		background-color: transparentize($tertiaryColor, 0.25); }
	> li > a {
		text-transform: uppercase; } }
.tablet-show {
	display: none; }
@media screen and (min-width: 768px) and (max-width: 991px) {
	.tablet-show {
		display: block; }
	.menu-right {
		display: none; } }

@media (min-width: 768px) {
	.main-nav {
		position: absolute;
		background-color: transparent; }
	#main-menu > ul {
		float: right;
		clear: none;
		display: block;
		> li > a > .sub-arrow {
			display: none; } } }


/* Mobile menu toggle button */

.main-menu-btn {
	float: right;
	margin: 5px 10px;
	position: relative;
	display: inline-block;
	width: 29px;
	height: 29px;
	text-indent: 29px;
	white-space: nowrap;
	overflow: hidden;
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0); }


/* hamburger icon */

.main-menu-btn-icon,
.main-menu-btn-icon:before,
.main-menu-btn-icon:after {
	position: absolute;
	top: 50%;
	left: 2px;
	height: 2px;
	width: 24px;
	background: #555;
	-webkit-transition: all 0.25s;
	transition: all 0.25s; }

.main-menu-btn-icon:before {
	content: '';
	top: -7px;
	left: 0; }

.main-menu-btn-icon:after {
	content: '';
	top: 7px;
	left: 0; }

/* x icon */

#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon {
	height: 0;
	background: transparent; }

#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before {
	top: 0;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg); }

#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after {
	top: 0;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg); }

/* hide menu state checkbox (keep it visible to screen readers) */

#main-menu-state {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px); }

/* hide the menu in mobile view */

#main-menu-state:not(:checked) ~ #main-menu {
	display: none; }

#main-menu-state:checked ~ #main-menu {
	display: block; }

@media (min-width: 768px) {
	/* hide the button in desktop view */
	.main-menu-btn {
		position: absolute;
		top: -99999px; }
	/* always show the menu in desktop view */
	#main-menu-state:not(:checked) ~ #main-menu {
		display: block; } }












ul#lang {
	list-style: none;
	margin: 0;
	*zoom: 1;
	li {
		float: left;
		visibility: collapse;
		a {
			display: block;
			padding: 3px 10px;
			visibility: visible; } }
	&:before,
	&:after {
		content: ' ';
		display: table; }
	&:after {
		clear: both; }
	&.flag {
		a {
			width: 2rem;
			height: 1.5rem;
			background-size: contain;
			background-position: 50%;
			background-repeat: no-repeat; } }
	&.code {
		a {
			&:before {
				content: attr(data-code);
				text-transform: uppercase; } } }
	&.name {
		a {
			&:before {
				content: attr(title); } } } }



.relative {
	position: relative; }

.align-center {
	text-align: center; }
.middle {
	@include verticalAlign(); }


.bold {
	font-weight: bold; }
.uppercase {
	text-transform: uppercase; }


/* line break after element */
.br {
	&:after {
		content: '\A';
		white-space: pre; } }

/* clear-fix */
.cf {
	*zoom: 1;
	&:before,
	&:after {
		content: ' ';
		display: table; }
	&:after {
		clear: both; } }

@media screen and (max-width: 1024px) {
	.cf-medium {
		*zoom: 1;
		&:before,
		&:after {
			content: ' ';
			display: table; }
		&:after {
			clear: both; } } }
@media screen and (min-width: 1025px) {
	.small-0 {
		width: 0; } }


/* proportional height */
#testProportional {
	margin: 50px auto;
	width: 300px;
	background-color: green; }

.crust {
	position: relative;
	width: 100%;
	height: auto;
	overflow: hidden;
	&:before {
		content: '';
		display: block;
		padding-top: 100%; }
	.core {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		text-align: center;
		img,
		.content {
			max-width: 100%;
			max-height: 100%;
			height: auto;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto; }
		.content {
			width: 100%;
			height: 100%; } }

	&.cover .core img {
		/*width: 100%*/
		/*height: auto*/
		/*max-height: none*/
		min-width: 100%;
		min-height: 100%;
		height: auto;
		max-width: none; }
	&.ratio-2_1:before {
		padding-top: 50%; }
	&.ratio-4_3:before {
		padding-top: 75%; }
	&.ratio-16_9:before {
		padding-top: 56.25%; }
	&.ratio-9_4:before {
		padding-top: 44.44%; }
	&.ratio-5_4:before {
		padding-top: 80%; }
	&.ratio-1_2:before {
		padding-top: 200%; }
	&.ratio-4_5:before {
		padding-top: 125%; } }

/* icon before content */
a.icon-prev {
	&:hover, &:focus {
		color: lighten($primaryColor, 20); } }
.content {
	p {
		line-height: 24px; }
	ul {
		li {
			line-height: 24px;
			margin-bottom: 15px; } } }
.icon-prev {
	position: relative;
	display: inline;
	margin-left: 2.25em;
	white-space: nowrap;
	&:before {
		position: absolute;
		margin-top: 0.15em;
		left: -1.20em;
		font-size: 1.10em;
		color: $quaternaryColor; }
	&:first-child {
		margin-left: 1.35em; } }
.quick-search-wrapp {
	position: absolute;
	top: 350px;
	z-index: 99;
	width: 100%;
	.quick-search {
		background-color: $primaryColor;
		width: 825px;
		border-radius: 18px;
		border: 9px solid white;
		text-align: center;
		margin: 0 auto;
		.order-btn {
			margin: 0 auto 10px;
			width: 230px; }
		@media screen and (max-width: 767px) {
			width: 100%; }
		p {
			color: white;
			font-size: 24px;
			font-weight: bolder;
			margin: 15px 0; }
		.search-diff {
			color: white;
			margin-bottom: 0;
			display: block;
			background-color: #00a7d8;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
			height: 40px;
			font-size: 15px;
			padding-top: 8px;
			&:hover, &:focus {
				color: $secondaryColor;
				-webkit-transition: 1s;
				transition: 1s; } }
		i {
			background-image: url(../images/icons/arrow_down.svg);
			display: inline-block;
			height: 15px;
			width: 15px;
			background-repeat: no-repeat;
			position: relative;
			top: 2px;
			left: 5px; } } }
.padd {
	p {
		color: white;
		font-size: 50px;
		margin-top: 120px;
		display: block;
		margin-left: 120px;
		color: #1b75bb;
		text-decoration: none;
		cursor: default;
		&:hover, &:focus {
			text-decoration: none; }
		a {
			color: #1b75bb;
			cursor: pointer;
			&:hover, &:focus {
				color: #1b75bb;
				text-decoration: none; } } } }


/* title after icon */
.tai {
	position: relative;
	white-space: nowrap;
	color: $primaryColor;
	font-size: $base-font-size;
	line-height: $base-line-height;
	margin-right: $base-font-size;
	&:after {
		content: attr(data-title);
		position: relative;
		display: inline-block;
		font-size: $base-font-size * 0.75;
		line-height: $base-line-height;
		margin-left: $base-font-size * 0.33; }
	&:before {
		line-height: $base-line-height; } }


@media screen and (max-width: 991px) {

	.tai {
		cursor: help;
		font-size: $base-font-size * 2.5;
		&:after {
			display: none; }
		&:hover:after,
		&:focus:after,
		&:active:after {
			display: block;
			position: absolute;
			top: $base-line-height * 0.5;
			left: 100%;
			z-index: 1000;
			background-color: transparentize($tertiaryColor, 0.15);
			font-size: $base-font-size;
			line-height: $base-line-height;
			padding: 1px 4px;
			@include border-radius(2px); }
		&:before {
			line-height: $base-line-height * 2.5; } }
	a.tai {
		cursor: pointer; } }
/**
 * Checkbox Two
 */
.language {
	margin-top: 18px;
	span {
		color: $primaryColor;
		position: relative;
		top: 9px; } }

.checkboxTwo {
  width: 70px;
  height: 40px;
  background: transparent;
  border-radius: 50px;
  position: relative;
  background-color: #efefef;
  @media(max-width: 767px) {
 }    // transform: rotate(90deg)
  input[type="checkbox"] {
    visibility: hidden; } }

.checkboxTwo:before {
  position: absolute;
  top: 19px;
  left: 14px;
  height: 2px;
  width: 90px;
  background: white; }


.checkboxTwo label {
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  -o-transition: all .2s ease;
  -ms-transition: all .2s ease;
  transition: all .2s ease;
  cursor: pointer;
  position: absolute;
  top: 9px;
  z-index: 1;
  left: 9px;
  background-color: $primaryColor; }

.checkboxTwo input[type=checkbox]:checked + label {
  left: 37px;
  background: $primaryColor; }
.buttons {
 	background-color: #efefef;
 	margin-bottom: 50px;
 	padding-bottom: 46px;
 	.circle {
 		display: block;
 		height: 113px;
 		width: 113px;
 		background-repeat: no-repeat;
 		background-position: center;
 		top: -51px;
 		border: 10px solid #efefef;
 		border-radius: 58px;
 		margin: -50px auto 0;
 		@media screen and (max-width: 767px) {
 			margin: 40px auto 0; } }
 	.circle1 {
 		background-image: url(../images/icons/circle1.svg); }
 	.circle2 {
 		background-image: url(../images/icons/circle2.svg); }
 	.circle3 {
 		background-image: url(../images/icons/circle3.svg); }
 	.circle4 {
 		background-image: url(../images/icons/circle4.svg); }
 	.order-btn {
 		margin: 0 auto 20px;
 		border-color: rgb(239, 239, 239); }
 	h4 {
 		text-align: center;
 		margin: 12px auto 11px;
 		height: 84px;
 		line-height: 25px;
 		padding: 0 83px;
 		&:hover, &:focus {
 			text-decoration: none; }
 		@media screen and (max-width: 767px) {
 			height: 40px; }

 		@media (max-width: 1310px) {
 			padding: 0; } }
 	a:hover, &:focus {
 		text-decoration: none;
 		color: #337ab7; }
 	.row {
 		border-right: 2px solid #e3e3e3; }
 	.col-md-4 {
 		@media screen and (max-width: 767px) {
 			border-bottom: 2px solid #e3e3e3; }
 		&:last-child {
 			border-bottom: none;
 			.row {
 				border-right: none; } } }
 	.order-btn {
 		border: 3px solid #e0e0e0;
 		&:hover {
 			color: white; } } }

.main-bar {
	// background-color: #efefef
	h1 {
		font-weight: bolder;
		color: white;
		font-size: 40px; } }
.banner {
	background-image: url(../images/layout/banner.png);
	height: 222px;
	background-size: cover;
	background-repeat: no-repeat;
	margin-top: 20px; }
.article-wrapp {
	height: 400px;
	overflow: hidden;
	@media screen and (max-width: 767px) {
		height: 941px; }
	@media screen and (max-width: 468px) {
		height: 982px; } }
.articles-included {
	background-color: $primaryColor;
	min-height: 200px;
	h2 {
		text-transform: initial;
		font-weight: 100;
		font-size: 37px;
		margin: 45px;
		a {
			color: white;
			&:hover, &:focus {
				color: #97ca4e;
				text-decoration: none;
				-webkit-transition: 0.5s;
				transition: 0.5s; } } }
	.order-btn {
		margin: 25px auto 50px;
		width: 150px; }
	article, .gallery {
		background-color: white;
		border: 5px solid #00a7d8;
		border-radius: 10px;
		height: 285px;
		position: relative;
		margin-bottom: 30px;
		span {
			background-color: #efefef;
			display: block;
			color: #c4c4c4;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
			padding: 8px 25px;
			position: absolute;
			bottom: 0;
			width: 100%; }
		p, h3 {
			padding: 0 25px;
			color: black; }
		h4 {
			padding: 15px 25px 0;
			color: black;
			font-weight: bolder;
			i {
				display: inline-block;
				height: 15px;
				width: 15px;
				background-image: url(../images/icons/arrow_right.svg);
				background-repeat: no-repeat;
				position: relative;
				top: 3px;
				left: 5px; } }
		.short_content {
			height: 135px;
			overflow: hidden; }
		&:hover, &:focus {
			border: 5px solid white;
			text-decoration: none;
			-webkit-transition: 0.5s;
			transition: 0.5s;
			h3, h4 {
				color: $primaryColor;
				-webkit-transition: 0.5s;
				transition: 0.5s; } } } }

.lSAction {
	a {
		// width: 80px
		// height: 80px
		// background-repeat: no-repeat
 } }		// background-size: contain

.req-sign {
	color: $errorColor;
	font-weight: bold; }
label {
	.req::after {
		@extend .req-sign;
		content: '*';
		position: absolute;
		margin-left: 0.3rem {
	    margin-right: 1.2rem; } } }

.fancybox {
	display: inline-block; }
ul.evenly {
	text-align: justify;
	min-width: 500px;
	&:after {
		content: '';
		display: inline-block;
		width: 100%; }
	li {
		display: inline-block; } }

.clickable {
	cursor: zoom-in; }
#scrollDown {
	cursor: pointer; }

/* ADMIN: edit text on the page */
.admin-edit {
	position: relative;
	//border: 1px dashed transparentize($warningColor, 0.75)
	//background-color: transparentize(white, 0.95)
	overflow: hidden;
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		border: 1px solid transparentize(darken($warningColor, 30), 0.75);
		background-color: transparentize(black, 0.95);
		&:hover, &:focus {
			display: none; } }
	&:hover {
		border: 1px solid darken($errorColor, 10);
		background-color: transparentize(white, 0.33);
		color: darken($errorColor, 20);
		cursor: url(../images/pencil-red-16.gif) 0 16, pointer; }
	&[data-variant="static_content"] {
		display: inherit;
		&:hover, &:focus {
			cursor: url(../images/doc-edit-16.gif) 0 16, pointer; } } }
.menu-content-edit {
	@extend .admin-edit;
	display: inherit;
	&:hover, &:focus {
		cursor: url(../images/doc-edit-16.gif) 0 16, pointer; } }
.float-left {
	float: left; }
.float-right {
	float: right; }
.lSSlideOuter {
	width: 100%;
	height: 400px; }

#scrollHere {
	margin-top: 75px; }
.slider-mobile {
	display: none; }
@media (max-width: 767px) {
	.slider-mobile {
		display: block; }
	.slider-wrapp {
		display: none; }
	.quick-search-wrapp {
		position: relative;
		top: 10px;
		.quick-search {
			.order-btn {
				width: 100%; } } } }
@media (max-width: 830px) {
	.quick-search-wrapp {
		.quick-search {
			width: 100%; } } }

@media (max-width: 1100px) and (min-width: 992px) {
	header {
		#navbar {
			#logo {
				margin-left: 0;
				height: 40px;
				width: 123px;
				padding: 0;
				margin-top: 25px; } } } }

img {
	display: block;
	height: auto;
	max-width: 100%; }
@media screen and (max-width: 767px) {
	.static_content {
		p, ul, li {
			text-align: left !important; } }
	.banner {
		display: none; } }




@import 'footer';

@import 'modules/contact';
@import 'modules/home';
@import 'modules/article';
@import 'modules/gallery';
@import 'modules/templates';
@import 'modules/table';
