@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

.articles-included {
	.gallery {
		border: 5px solid #efefef;
		.image {
			background-repeat: no-repeat;
			width: 100%;
			height: 100%;
			background-size: cover;
			border-radius: 5px;
			h4, p {
				color: white; }
			h5 {
				margin: 0;
				color: white;
				font-size: 30px;
				padding: 10px 20px;
				font-weight: 700; }
			&:hover {
				opacity: 0.7; } }
		span {
			border-bottom-left-radius: 3px;
			border-bottom-right-radius: 3px;
			height: 37px;
			overflow: hidden;
			line-height: 23px; } } }
