@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

.order-back {
	background-color: $primaryColor;
	background-image: url(../images/layout/london-bg.svg);
	background-repeat: no-repeat;
	background-position: bottom;
	.page-contact {
		p {
			color: white;
			margin: 0;
			text-align: center;
			font-size: 1em;
			font-weight: bolder; }
		ul {
			margin-top: 10px;
			padding-left: 0;
			li {
				list-style-type: none;
				color: white;
				text-align: center;
				font-size: 1em;
				&:after {
					content: " ";
					width: 25px;
					height: 1px;
					background: #0089b1;
					display: block;
					margin: 10px 0; }
				&:last-child {
					&:after {
						background: transparent; } } } } }

	h1 {
		text-align: center;
		color: white;
		font-size: 2.5em;
		font-weight: bolder;
		margin-top: 45px; } }

textarea {
	height: 200px; }
.form-wrapp {
	.order-btn {
		float: right;
		border: 3px solid #1269ad;
		height: 50px;
		width: 167px;
		margin-top: 30px;
		&:hover {
			background-color: #9eda47; } } }
.form-wrapp, .order-form-wrapp {
	background-color: #1b75bb;
	min-height: 420px;
	margin: 0 -15px;
	h2 {
		text-align: left;
		color: white;
		margin-left: 15px; }
	.contact_form {
		padding: 18px;
		.g-recaptcha {
			float: left;
			margin: 15px 0 70px; }
		.text-right {
			width: 100%;
			display: block; }
		.form-group {
			margin-bottom: 1px;
			box-shadow: none; }
		input {
			height: 45px; }
		textarea {
			height: 100px; }
		input, textarea {
			box-shadow: none;
			display: block;
			width: 100%;
			border: none;
			margin-bottom: 1px;
			border-radius: 0;
			background-color: #1269ad;
			padding: 8px;
			color: white;
			font-family: inherit;
			::-webkit-input-placeholder {
				color: $primaryColor; }
			:-moz-placeholder {
				color: $primaryColor; }
			::-moz-placeholder {
				color: $primaryColor; }
			:-ms-input-placeholder {
				color: $primaryColor; }
			::-ms-input-placeholder {
				color: $primaryColor; } }
		input[type="submit"] {
			width: 100% !important; } } }
.order-form-wrapp {
	background-color: transparent;
	margin: 0;
	margin-top: 25px;
	.contact_form {
		padding: 0;
		input, textarea {
			background-color: white;
			color: black; } }
	.order-btn {
		float: none;
		display: block;
		margin: 30px auto;
		width: 185px; } }
.rc-anchor-light {
	background-color: #00b2e6;
	.rc-anchor-normal {
		border: 1px solid #00b2e6; } }
.order-back {
	.page-contact {
		p {
			text-align: left; }
		ul {
			li {
				text-align: left; } } } }
.gdpr_content {
	font-weight: 100;
	color: #fff;
	h4 {
		color: #fff; } }
