article {
	background-color: #f7f7f7;
	h4 {
		text-align: left; } }
.item {
	margin-bottom: 25px;
	border: 2px solid #f8b134; }

.faq-detail {
	margin-bottom: 50px;
	p, li {
		font-weight: 100;
		margin-bottom: 20px;
		font-size: 1em;
		line-height: 1.5em; }
	span {
		display: block; }
	.order-btn {
		margin: 0 auto 100px; } }

.reference-article {
	margin-bottom: 20px;
	padding: 25px;
	background-color: #f1f6fa;
	.content-article,
	h4 {
		p {
			display: inline-block;
			font-family: 'Roboto Condensed';
			font-size: 16px; } }
	h4 {
		margin-bottom: 0px;
		p {
			margin-bottom: 0;
			font-weight: bold; } } }
.article-content {
	height: 155px;
	overflow: hidden; }
.blue-back {
	background-color: $primaryColor;
	margin-bottom: 0; }
.hide-articles {
	display: none; }
#show-articles {
	margin: 0 auto 30px;
	cursor: pointer; }
