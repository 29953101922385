@CHARSET 'UTF-8' {}

@import 'variables';
@import 'mixins';

footer {
	background-color: $tertiaryColor;
	color: white;
	min-height: 360px;
	position: relative;
	a {
		color: $secondaryColor;
		&:hover {
			color: #9eda47;
			text-decoration: none; } }
	ul {
		padding-left: 0;
		li {
			list-style-type: none; } }
	.facebook-news {
		margin: 0 auto 74px;
		background-image: url(../images/icons/fb.svg);
		display: block;
		height: 64px;
		width: 195px;
		background-repeat: no-repeat;
		span {
			display: inline-block;
			font-weight: bolder;
			margin-left: 15px; } }
	#logo-footer {
		background-image: url(../images/icons/logo_seven_footer.svg);
		background-repeat: no-repeat;
		display: block;
		margin: 45px auto 0;
		width: 188px;
		height: 72px;
		background-size: contain;
		background-position: center; }
	.middle-column {
		padding: 60px 0;
		border-left: 1px solid $primaryColor;
		border-right: 1px solid $primaryColor;
		margin: 0 -12px; }

	form {
		input {
			height: 45px; }
		textarea {
			height: 100px; }
		input, textarea {
			display: block;
			width: 100%;
			border: none;
			margin-bottom: 1px;
			border-radius: 0;
			background-color: #1269ad;
			padding: 8px;
			color: white;
			box-shadow: none;
			font-family: inherit;
			::-webkit-input-placeholder {
				color: #fff; }
			:-moz-placeholder {
				color: #fff; }
			::-moz-placeholder {
				color: #fff; }
			:-ms-input-placeholder {
				color: #fff; }
			::-ms-input-placeholder {
				color: #fff; } }
		input[type="submit"] {
			width: 100% !important;
			border: 3px solid #1269ad; }
		@media screen and (max-width: 991px) {
			margin-bottom: 25px; }
		@media screen and (max-width: 767px) {
			margin-bottom: 50px; } }
	.copyright {
		color: $primaryColor;
		text-align: center;
		margin-top: 33px;
		p, a {
			color: $primaryColor; } }
	.order-btn {
		border: 3px solid #1269ad; }
	#scrollTop {
		position: absolute;
		background-image: url(../images/icons/arrow_scroll_up.svg);
		height: 50px;
		width: 50px;
		display: block;
		background-repeat: no-repeat;
		background-size: contain;
		cursor: pointer;
		bottom: 25px;
		right: 25px;
		@media screen and (max-width: 767px) {
			bottom: 55px; } } }





