@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

.static-content {
	table {
		border: none;
		// color: white
		tr {
			background-color: #efefef;
			&:nth-child(2), &:first-child {
				background-color: $primaryColor; }
			&:first-child {
				color: white;
				font-weight: bolder;
				td:first-child {
					background-color: white; }
				td:nth-child(3) {
					background-color: #1b75bb; } }
			&:nth-child(2) {
				color: white;
				font-weight: bolder;
				td:nth-child(3),td:nth-child(4) {
					background-color: #1b75bb; } }


			td {
				border: 1px solid white; } } } }
@media screen and (max-width:500px) {
	.static-content {
		table {
			font-size: 10px; } } }
.faq-detail {
	table {
		border: none;
		width: 100%;
		tr {
			background-color: #efefef;
			td {
				height: 60px; }
			&:first-child {
				background-color: $primaryColor;
				color: white;
				font-weight: bolder;
				td {
					height: 40px;
					&:first-child {
						background-color: white; } } }
			td {
				border: 1px solid white;
				&:nth-child(1) {
					background-color: #1b75bb;
					color: white;
					font-weight: bolder; } }

			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5) {
				td {
					&:nth-child(2),
					&:nth-child(3),
					&:nth-child(4) {
						font-weight: 100; } } } } } }
@media screen and (max-width:500px) {
	.faq-detail {
		table {
			font-size: 10px;
			tr {
				td {
					height: 80px; } } } } }

.static-content {
	table.gdpr_table {
		width: 100%;
		tr {
			&:first-child {
				background-color: #00b2e6;
				td {
					&:first-child,  &:nth-child(3) {
						background-color: #00b2e6; } } }
			&:nth-child(2) {
				background-color: #efefef;
				td {
					font-weight: 100;
					color: #0a0a0a;
					&:nth-child(3) {
						background-color: #efefef; } } }
			td {
				padding: 5px 8px; } } } }
