$primaryColor: #00b2e6;		//tmavo modra
$secondaryColor: #97ca4e;	//zelena pre hvoer
$tertiaryColor: #1b75bb;		//biela
$quaternaryColor: red;	//
$pentiaryColor: green;		//
$facebook:  #3b5998;

$gray: #c0c0c0;

$errorColor: #cc0000;
$warningColor: #ffcc00;
$successColor: #33cc00;

$body-font-family: 'Teko', sans-serif;
$base-font-size: 1rem;
$base-line-height: 1.5rem;


$gutter: 15px;
